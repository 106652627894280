// AuthContext.js
import React, { createContext, useEffect, useState, useMemo } from "react";
import axios from "../api/axios";
// import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [clients, setClients] = useState([]);

  const [loggedIn, setLoggedIn] = useState(false);

  // Function to refresh user data
  const refreshUserData = async () => {
    try {
      const response = await axios.get("/handledata", {
        headers: {
          "x-auth-token": localStorage.getItem("authTokens"),
        },
      });
      setUserData(response.data.host);
      setClients(response.data.clients);
      setRefresh(false);
      setLoggedIn(true);
    } catch (error) {
      console.error("Error refreshing user data:", error);
      setLoggedIn(false);
    }
  };

  useEffect(() => {
    if (refresh) {
      refreshUserData();
    }
  }, [refresh]);
  useEffect(() => {
    refreshUserData();
  }, []);
  const memoizedUserData = useMemo(() => userData, [userData]);
  const memoizedClientsData = useMemo(() => clients, [clients]);

  return (
    <AuthContext.Provider
      value={{
        userData: memoizedUserData,
        clients: memoizedClientsData,
        setRefresh,
        isLoggedIn: loggedIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
