import React, { useEffect, useState } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import LoginImg from "../assets/images/loginimg.jpg";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "../api/axios";
import "../styles/fontStyle.css";
const logincarouselArr = [
  {
    id: 1,
    content: "Delivering Messages, Building Connections",
  },
  {
    id: 2,
    content: "Crafting Emails, Driving Engagement",
  },
  { id: 3, content: "Seamless Communication, Powerful Results" },
];
const SignUpPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("authTokens");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp > currentTime) {
          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Invalid token", error);
      }
    }
  }, [navigate]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === logincarouselArr.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval); // Clear interval on unmount
  }, [logincarouselArr]);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/login", {
        username,
        password,
      });
      console.log(response.data);
      if (response.status === 200) {
        console.log(response.data);
        localStorage.setItem("authTokens", response.data.authToken);
        setTimeout(() => {
          // toast.success("Login successful");
          navigate("/dashboard");
        }, 500);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      // toast.error("Login failed");
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        backgroundColor: "#2B2738",
        overflow: "hidden",
      }}
    >
      {/* Left Image Section */}
      <Box
        sx={{
          flex: 1,
          position: "relative",
          backgroundImage: `url(${LoginImg})`, // Replace with the actual image URL
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderRadius: "20px",
          margin: "15px",
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          justifyContent: "space-between",
          color: "#fff",
          padding: "1rem",
        }}
      >
        {/* <Box>
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              fontFamily: "sans-serif",
              marginBottom: "2rem",
            }}
          >
            AMU
          </Typography>
        </Box> */}
        <Box
          sx={{
            position: "relative",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // backgroundColor: "#1E1E2F",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "5px",
              right: "20px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              padding: "10px 20px",
              borderRadius: "20px",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
              },
            }}
          >
            <a
              href="https://quantumculture.in"
              style={{
                textDecoration: "none",
                color: "rgba(255, 255, 255, 0.6)",
                fontFamily: "sans-serif",
              }}
            >
              Back to Website
            </a>
            <TrendingFlatIcon sx={{ color: "rgba(255, 255, 255, 0.6)" }} />
          </Box>
          {/* Text Carousel */}
          {logincarouselArr.map((item, index) => (
            <Typography
              key={item.id}
              variant="h6"
              sx={{
                position: "absolute",
                bottom: "40px",
                opacity: index === currentIndex ? 1 : 0,
                transition: "opacity 0.5s ease-in-out",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {item.content}
            </Typography>
          ))}

          {/* Carousel Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              position: "absolute",
              bottom: "20px",
              gap: "10px",
            }}
          >
            {logincarouselArr.map((_, index) => (
              <Box
                key={index}
                sx={{
                  width: index === currentIndex ? "30px" : "20px",
                  height: "6px",
                  borderRadius: "5px",
                  backgroundColor: index === currentIndex ? "#fff" : "#7F668D",
                  transition: "all 1s ease",
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>

      {/* Right Form Section */}
      <Box
        sx={{
          flex: 1,
          padding: "4rem",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          color: "#fff",
          backgroundColor: "#2B2738",
          borderRadius: "0 20px 20px 0",
          backgroundImage: { xs: `url(${LoginImg})`, sm: "none" }, // Replace with the actual image URL
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* <img
          src={QcLogo}
          alt="Quantum Culture Technologies Logo"
          style={{ width: "200px" }}
        /> */}
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            marginBottom: "1rem",
            textAlign: "center",
            fontFamily: "helvetica",
            color: "rgba(255, 255, 255, 0.8)",
            whiteSpace: "nowrap",
          }}
        >
          Quantum Mailer
        </Typography>

        <TextField
          fullWidth
          label="Username"
          variant="outlined"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{
            backgroundColor: "#3B3B4F",
            borderRadius: "8px",
            marginY: "1rem",
          }}
          InputProps={{ style: { color: "#fff" } }}
          InputLabelProps={{
            style: {
              color: "rgba(255, 255, 255, 0.6)", // White label color
            },
          }}
        />
        <TextField
          fullWidth
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          variant="outlined"
          sx={{
            backgroundColor: "#3B3B4F",
            borderRadius: "8px",
            marginBottom: "1rem",
          }}
          InputProps={{ style: { color: "#fff" } }}
          InputLabelProps={{
            style: {
              color: "rgba(255, 255, 255, 0.6)", // White label color
            },
          }}
        />

        <Button
          fullWidth
          onClick={handleLogin}
          variant="contained"
          sx={{
            backgroundColor: "#7C4DFF",
            padding: "1rem",
            marginTop: "1rem",
            borderRadius: "8px",
            fontSize: "1rem",
          }}
        >
          Login
        </Button>
        <Typography
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            textAlign: "center",
            marginTop: "1rem",
          }}
        >
          Powered by
          <br />
          <a href="https://quantumculture.in" style={{ color: "#7C4DFF" }}>
            Quantum Culture Technologies
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default SignUpPage;
