import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/sidebar";
import AppBar from "../components/appbar";
import ThemeContext from "../contexts/ThemeContext";
import Drawer from "../components/drawer";

const Layout = () => {
  const { theme } = useContext(ThemeContext);
  const [openSidebar, setOpenSidebar] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleCloseSidebar = () => {
    setOpenSidebar(!openSidebar);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        backgroundColor: theme.primary.mainBackground,
        overflow: "hidden",
      }}
    >
      <Sidebar
        openSidebar={openSidebar}
        handleCloseSidebar={handleCloseSidebar}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          margin: "5px",
        }}
      >
        <AppBar
          openSidebar={openSidebar}
          handleCloseSidebar={handleCloseSidebar}
          openDrawer={openDrawer}
          handleCloseDrawer={handleCloseDrawer}
        />
        <Box
          sx={{
            height: "100%",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          <Outlet />
        </Box>
      </Box>
      <Drawer openDrawer={openDrawer} handleCloseDrawer={handleCloseDrawer} />
    </Box>
  );
};

export default Layout;
