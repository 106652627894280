import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login";
import Layout from "./pages/layout";
import Dashboard from "./pages/Dashboard/dashboard";
import Clients from "./pages/Dashboard/clients";
import { AuthProvider } from "./contexts/authContext";
import AddClient from "./components/addclient";
import RequireAuth from "./contexts/requireAuth";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route element={<RequireAuth />}>
          <Route
            path="/dashboard"
            element={
              <AuthProvider>
                {" "}
                <Layout />
              </AuthProvider>
            }
          >
            <Route index element={<Dashboard />} />
            <Route path="clients" element={<Clients />} />
            <Route path="settings" element={<div>Settings</div>} />
            <Route path="clients/add" element={<AddClient />} />
            <Route path="clients/edit/:id" element={<AddClient />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default App;
