import React, { useContext } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import ThemeContext from "../contexts/ThemeContext";

const Sidebar = ({ openSidebar }) => {
  const location = useLocation();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();
  // Helper function to check if a link is active
  const isActive = (path) => location.pathname === path;

  const handleLogout = () => {
    // Handle logout logic here
    localStorage.clear();
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: { xs: "none", sm: openSidebar ? "flex" : "none" },
        margin: "10px",
        borderRadius: "10px",
        flexDirection: "column",
        width: openSidebar ? "250px" : "0px",
        height: "100%",
        backgroundColor: theme.primary.subBackground,
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          component={Link}
          to="/dashboard"
          sx={{
            display: "flex",
            height: "100px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            textAlign={"center"}
            sx={{ color: theme.primary.text }}
          >
            Q Mailer
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            component={Link}
            to="/dashboard"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
              alignItems: "center",
              backgroundColor: isActive("/dashboard")
                ? theme.primary.background
                : "transparent",
              color: isActive("/dashboard")
                ? theme.primary.buttonText
                : theme.primary.text,
              padding: "5px 15px",
              borderRadius: "10px",
              margin: "0px 20px",
              cursor: "pointer",
            }}
          >
            <DashboardIcon />
            <Typography sx={{ fontWeight: "bolder" }}>Dashboard</Typography>
          </Box>
          <Divider
            sx={{
              width: "80%",
              background: theme.primary.background,
              borderWidth: 1,
            }}
            variant="middle"
            flexItem
          />
          <Box
            component={Link}
            to="clients"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "20px",
              alignItems: "center",
              backgroundColor: isActive("/dashboard/clients")
                ? theme.primary.background
                : "transparent",
              color: isActive("/dashboard/clients")
                ? theme.primary.buttonText
                : theme.primary.text,
              padding: "5px 15px",
              borderRadius: "10px",
              margin: "0px 20px",
              cursor: "pointer",
            }}
          >
            <GroupsIcon />
            <Typography sx={{ fontWeight: "bolder" }}>Clients</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        component={Button}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.primary.background,
          color: theme.primary.buttonText,
          cursor: "pointer",
          margin: "20px 0px",
        }}
        onClick={handleLogout}
      >
        Logout
      </Box>
    </Box>
  );
};

export default Sidebar;
