import React, { useContext } from "react";
import { Box, Button } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import MenuOpenRoundedIcon from "@mui/icons-material/MenuOpenRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ThemeContext from "../contexts/ThemeContext";
const AppBar = ({
  handleCloseSidebar,
  openSidebar,
  openDrawer,
  handleCloseDrawer,
}) => {
  const { theme, handleThemeChange } = useContext(ThemeContext);
  return (
    <Box
      sx={{
        display: "flex",
        height: "60px",
        margin: "10px",
        borderRadius: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: theme.primary.subBackground,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          marginLeft: "10px",
        }}
      >
        <Button
          onClick={handleCloseDrawer}
          sx={{
            display: { xs: "flex", sm: "none" },
            backgroundColor: theme.primary.background,
            padding: "6px",
            borderRadius: "10px",
          }}
        >
          {openDrawer ? (
            <MenuOpenRoundedIcon sx={{ color: theme.primary.buttonText }} />
          ) : (
            <MenuRoundedIcon sx={{ color: theme.primary.buttonText }} />
          )}
        </Button>
        <Button
          onClick={handleCloseSidebar}
          sx={{
            display: { xs: "none", sm: "flex" },
            backgroundColor: theme.primary.background,
            padding: "6px",
            borderRadius: "10px",
          }}
        >
          {openSidebar ? (
            <MenuOpenRoundedIcon sx={{ color: theme.primary.buttonText }} />
          ) : (
            <MenuRoundedIcon sx={{ color: theme.primary.buttonText }} />
          )}
        </Button>
      </Box>
      <Box sx={{ display: "flex", gap: "10px", marginRight: "10px" }}>
        <Button
          onClick={handleThemeChange}
          sx={{
            backgroundColor: theme.primary.background,
            padding: "6px",
            borderRadius: "10px",
          }}
        >
          <LightModeIcon
            sx={{
              color: theme.primary.buttonText,
            }}
          />
        </Button>
        <Button
          sx={{
            backgroundColor: theme.primary.background,
            padding: "6px",
            borderRadius: "10px",
          }}
        >
          <NotificationsRoundedIcon
            sx={{
              color: theme.primary.buttonText,
            }}
          />
        </Button>
        <Button
          sx={{
            backgroundColor: theme.primary.background,
            padding: "6px",
            borderRadius: "10px",
          }}
        >
          <PersonRoundedIcon
            sx={{
              color: theme.primary.buttonText,
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default AppBar;
