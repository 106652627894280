import React, { useContext } from "react";
import { Box, Button, Divider, Typography, Drawer } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import ThemeContext from "../contexts/ThemeContext";

const Drawers = ({ openDrawer, handleCloseDrawer }) => {
  const location = useLocation();
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  const isActive = (path) => location.pathname === path;

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    handleCloseDrawer(); // Close drawer after logout
  };

  const handleNavigation = () => {
    handleCloseDrawer(); // Close drawer when a navigation link is clicked
  };

  return (
    <Drawer
      open={openDrawer}
      onClose={handleCloseDrawer}
      anchor="left" // Opens the drawer from the left side
    >
      <Box
        sx={{
          width: "250px", // Drawer width
          height: "100%",
          backgroundColor: theme.primary.subBackground,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Box>
          <Box
            component={Link}
            to="/dashboard"
            onClick={handleNavigation}
            sx={{
              display: "flex",
              height: "100px",
              alignItems: "center",
              justifyContent: "center",
              textDecoration: "none",
            }}
          >
            <Typography
              variant="h5"
              textAlign="center"
              sx={{ color: theme.primary.text }}
            >
              Q Mailer
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Box
              component={Link}
              to="/dashboard"
              onClick={handleNavigation}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "20px",
                alignItems: "center",
                textDecoration: "none",
                backgroundColor: isActive("/dashboard")
                  ? theme.primary.background
                  : "transparent",
                color: isActive("/dashboard")
                  ? theme.primary.buttonText
                  : theme.primary.text,
                padding: "5px 15px",
                borderRadius: "10px",
                margin: "0px 10px",
                cursor: "pointer",
              }}
            >
              <DashboardIcon />
              <Typography sx={{ fontWeight: "bolder" }}>Dashboard</Typography>
            </Box>
            <Divider
              sx={{
                width: "80%",
                background: theme.primary.background,
                borderWidth: 1,
                margin: "10px auto",
              }}
              variant="middle"
              flexItem
            />
            <Box
              component={Link}
              to="/dashboard/clients"
              onClick={handleNavigation}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "20px",
                alignItems: "center",
                textDecoration: "none",
                backgroundColor: isActive("/dashboard/clients")
                  ? theme.primary.background
                  : "transparent",
                color: isActive("/dashboard/clients")
                  ? theme.primary.buttonText
                  : theme.primary.text,
                padding: "5px 15px",
                borderRadius: "10px",
                margin: "0px 10px",
                cursor: "pointer",
              }}
            >
              <GroupsIcon />
              <Typography sx={{ fontWeight: "bolder" }}>Clients</Typography>
            </Box>
          </Box>
        </Box>
        <Button
          onClick={handleLogout}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: theme.primary.background,
            color: theme.primary.buttonText,
            cursor: "pointer",
            margin: "20px auto",
          }}
        >
          Logout
        </Button>
      </Box>
    </Drawer>
  );
};

export default Drawers;
