import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import AuthContext from "../../contexts/authContext";
import ThemeContext from "../../contexts/ThemeContext";

const Dashboard = () => {
  const { clients } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [dashboardData, setDashboardData] = useState({
    clients: 0,
    totalleads: 0,
    averageleads: 0,
  });

  useEffect(() => {
    if (clients) {
      const leads = clients.reduce(
        (total, client) => total + client.leads.length,
        0
      );
      const average = leads / clients.length;
      setDashboardData({
        clients: clients.length,
        totalleads: leads,
        averageleads: average,
      });
    }
  }, [clients]);

  return (
    <Box>
      {/* Box Types */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          flexWrap: "wrap",
        }}
      >
        {/* Clients */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "300px",
            alignItems: "center",
            height: "100px",
            borderRadius: "15px",
            border: "2px solid black",
            backgroundColor: theme.primary.background,
            color: theme.primary.buttonText,
            // backgroundImage: "linear-gradient(to bottom right, #01b2ea, red)",
          }}
        >
          <Typography sx={{ fontSize: "24px" }}>Clients</Typography>
          <Typography sx={{ fontSize: "32px" }}>
            {dashboardData.clients}
          </Typography>
        </Box>
        {/* Total Leads */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "300px",
            alignItems: "center",
            height: "100px",
            borderRadius: "15px",
            border: "2px solid black",
            backgroundColor: theme.primary.background,
            color: theme.primary.buttonText,
            // backgroundImage: "linear-gradient(to bottom right, #01b2ea, red)",
          }}
        >
          <Typography sx={{ fontSize: "24px" }}>Total Leads</Typography>
          <Typography sx={{ fontSize: "32px" }}>
            {dashboardData.totalleads}
          </Typography>
        </Box>
        {/* Average Leads */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            width: "300px",
            alignItems: "center",
            height: "100px",
            borderRadius: "15px",
            border: "2px solid black",
            backgroundColor: theme.primary.background,
            color: theme.primary.buttonText,
            // backgroundImage: "linear-gradient(to bottom right, #01b2ea, red)",
          }}
        >
          <Typography sx={{ fontSize: "24px" }}>Average Leads</Typography>
          <Typography sx={{ fontSize: "32px" }}>
            {Math.round(dashboardData.averageleads)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
