import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
} from "@mui/material";
import PersonAddAlt1RoundedIcon from "@mui/icons-material/PersonAddAlt1Rounded";
import Buttons from "../../components/Buttons";
import Grid from "../../components/dataGrid";
import AuthContext from "../../contexts/authContext";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axios";
import CheckLeads from "../../components/leads";
import ThemeContext from "../../contexts/ThemeContext";

const Clients = () => {
  const { theme } = useContext(ThemeContext);
  const [gridToolbar, setGridToolbar] = useState(false);
  const [clientData, setClientData] = useState([]);
  const { clients, setRefresh } = useContext(AuthContext);
  const [openLeads, setOpenLeads] = useState(false);
  const [clientId, setClientId] = useState("");

  const navigate = useNavigate();

  const handleCloseLeads = () => {
    setOpenLeads(false);
    setClientId("");
  };

  const handleDeleteClient = async (id) => {
    try {
      const response = await axiosInstance.delete(`/deleteclient/${id}`);
      if (response.status === 200) {
        console.log("Client Deleted");
        setRefresh(true);
      }
    } catch (error) {
      console.log("Error Deleting Client", error);
      alert("Client Delete Error");
    }
  };

  const handleCheckLeads = (id) => {
    setOpenLeads(true);
    setClientId(id);
  };

  // Update clientData whenever clients change
  useEffect(() => {
    if (clients?.length > 0) {
      setClientData(clients.slice().reverse()); // Use slice to avoid mutating the original array
    } else {
      setRefresh(true);
    }
  }, [clients]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 60,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "phone",
      headerName: "Phone",
      maxWidth: 150,
      flex: 1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
    },
    {
      field: "domain",
      headerName: "Domain",
      minWidth: 130,
      flex: 1,
      headerAlign: "center",
      align: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "leads",
      headerName: "Leads",
      maxWidth: 80,
      flex: 1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            gap: "5px",
          }}
        >
          <IconButton
            onClick={() => handleCheckLeads(params.row._id)}
            sx={{
              padding: "10px",
              color: theme.primary.text,
              "&:hover": {
                backgroundColor: theme.primary.subBackground,
              },
            }}
          >
            <Typography>{params.row.leads}</Typography>
          </IconButton>
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      maxWidth: 100,
      flex: 1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
    },

    {
      field: "action",
      headerName: "Action",
      maxWidth: 120,
      flex: 1,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
      align: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            gap: "5px",
          }}
        >
          <IconButton
            onClick={() => navigate("edit/" + params.row._id)}
            sx={{
              padding: "10px", // remove padding
              color: theme.primary.text,
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteClient(params.row._id)}
            sx={{
              padding: "10px",
              color: theme.primary.text,
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const rows = clientData?.map((item, index) => ({
    id: index + 1,
    _id: item._id,
    name: item.name,
    company: item.company,
    email: item.email,
    phone: item.phone || 0,
    domain: item.domain,
    leads: item.leads.length,
    status: item.status,
  }));

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Buttons
          text="Add Client"
          icon={<PersonAddAlt1RoundedIcon />}
          onClick={() => navigate("add")}
          sx={{
            backgroundColor: theme.primary.background,
            color: theme.primary.buttonText,
          }}
        />
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={gridToolbar}
                onChange={() => setGridToolbar(!gridToolbar)}
                name="enableFilters"
                sx={{
                  color: theme.primary.text, // Checkbox color when unchecked
                  "&.Mui-checked": {
                    color: theme.primary.text, // Checkbox color when checked
                  },
                }}
              />
            }
            label="Enable Filters"
            sx={{
              color: theme.primary.text, // Label color
            }}
          />
        </FormControl>
      </Box>
      <Box>
        <Grid columns={columns} gridToolbar={gridToolbar} rows={rows} />
      </Box>
      <CheckLeads
        openLeads={openLeads}
        handleCloseLeads={handleCloseLeads}
        clientId={clientId}
      />
    </Box>
  );
};

export default Clients;
