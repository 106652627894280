import axios from "axios";

export const baseURL = "https://www.sendmail.quantumculture.in";
// export const baseURL = "http://localhost:4100";

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: baseURL,
});

// Use interceptor to dynamically update auth token and other headers
axiosInstance.interceptors.request.use((config) => {
  const authTokens = localStorage.getItem("authTokens");

  if (authTokens) {
    config.headers["auth-token"] = authTokens;
  }

  return config;
});

export default axiosInstance;
