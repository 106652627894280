import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const RequireAuth = () => {
  const token = localStorage.getItem("authTokens");
  const location = useLocation();

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      if (decodedToken.exp > currentTime) {
        return <Outlet />;
      }
    } catch (error) {
      console.error("Invalid token", error);
    }
  }

  return <Navigate to="/" state={{ from: location }} replace />;
};

export default RequireAuth;

// export const getRoles = () => {
//   let token = localStorage.getItem("authTokens");

//   if (token) {
//     const decodedToken = jwtDecode(token);
//     const roles = decodedToken.role;
//     return roles;
//   }

//   // Return a default value if no token is found
//   return [];
// };
