import React, { createContext, useState } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false);
  const handleThemeChange = () => {
    setDarkMode(!darkMode);
    console.log(darkMode);
  };

  const lightTheme = {
    primary: {
      background: "#000",
      buttonText: "#fff",
      subBackground: "#F4F5F7",
      mainBackground: "#fff",
      text: "#000",
    },
  };

  const darkTheme = {
    primary: {
      background: "#fff",
      buttonText: "#000",
      subBackground: "#272727",
      mainBackground: "#000",
      text: "#fff",
    },
  };
  const theme = darkMode ? darkTheme : lightTheme;

  return (
    <ThemeContext.Provider
      value={{
        handleThemeChange,
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
