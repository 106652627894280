import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Buttons from "./Buttons";
import axiosInstance from "../api/axios";
import { useParams, useNavigate } from "react-router-dom";
import AuthContext from "../contexts/authContext";
import ThemeContext from "../contexts/ThemeContext";

const AddClient = () => {
  const { theme } = useContext(ThemeContext);
  const params = useParams();
  const navigate = useNavigate();
  const { clients, setRefresh } = useContext(AuthContext);
  const [editMode, setEditMode] = useState(false);
  const [showHostDetails, setShowHostDetails] = useState(false);
  const [clientDetails, setClientDetails] = useState({
    name: "",
    email: "",
    phone: 0,
    address: "",
    status: "active",
  });
  const [companyDetails, setCompanyDetails] = useState({
    company: "",
    domain: "",
    clientmail: "",
    logo: "",
    whatsapp: 0,
    ccemail: "",
    bccemail: "",
  });
  const [hostDetails, setHostDetails] = useState({
    host: "",
    port: 0,
    secure: true,
    authemail: "",
    authpass: "",
  });
  const [mailValue, setMailValue] = useState("default");
  const [replyValue, setReplyValue] = useState(false);
  const TextFields = ({ label, name, value, onChange, type, required }) => {
    return (
      <TextField
        sx={{
          "& .MuiInputBase-input": { color: theme.primary.text },
          "& .MuiInputLabel-root": { color: theme.primary.text },
          border: `1px solid ${theme.primary.text}`,
        }}
        name={name}
        fullWidth
        required={required}
        label={label}
        variant="outlined"
        type={type}
        value={value}
        onChange={onChange}
      />
    );
  };

  const handleHostChange = (mailVal, replyVal) => {
    // Logic: Show details if either `mailVal` is `host` or `replyVal` is `yes`
    if (mailVal === "host" || replyVal === true) {
      setShowHostDetails(true);
    } else {
      setShowHostDetails(false);
    }
  };

  const handleMailChange = (val) => {
    setMailValue(val);
    handleHostChange(val, replyValue); // Pass the latest states
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setCompanyDetails({ ...companyDetails, logo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleReplyChange = (val) => {
    setReplyValue(val);
    handleHostChange(mailValue, val); // Pass the latest states
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !clientDetails.name ||
      !clientDetails.email ||
      !clientDetails.phone ||
      !clientDetails.address ||
      !companyDetails.company ||
      !companyDetails.domain ||
      !companyDetails.clientmail
    ) {
      return alert("Please fill all the fields");
    }
    try {
      const response = await axiosInstance.post("/addclient", {
        name: clientDetails.name,
        email: clientDetails.email,
        phone: clientDetails.phone,
        address: clientDetails.address,
        host: hostDetails.host,
        port: hostDetails.port,
        secure: hostDetails.secure,
        authemail: hostDetails.authemail,
        authpass: hostDetails.authpass,
        clientmail: companyDetails.clientmail,
        ccemail: companyDetails.ccemail,
        bccemail: companyDetails.bccemail,
        whatsapp: companyDetails.whatsapp,
        company: companyDetails.company,
        domain: companyDetails.domain,
        replymail: replyValue,
        mailing: mailValue,
        status: clientDetails.status,
        logo: companyDetails.logo,
      });
      if (response.status === 200) {
        alert("Client Added Successfully");
        setRefresh(true);
        console.log(response.data);
        navigate("/dashboard/clients");
        setClientDetails({
          name: "",
          email: "",
          phone: 0,
          address: "",
          status: "active",
        });
        setCompanyDetails({
          company: "",
          domain: "",
          clientmail: "",
          ccemail: "",
          logo: "",
          whatsapp: 0,
        });
        setHostDetails({
          host: "",
          port: 0,
          secure: true,
          authemail: "",
          authpass: "",
        });
      }
    } catch (error) {
      console.error("Error adding client:", error);
      alert("Failed to add client. Please try again.");
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (
      !clientDetails.name ||
      !clientDetails.email ||
      !clientDetails.phone ||
      !clientDetails.address ||
      !companyDetails.company ||
      !companyDetails.domain ||
      !companyDetails.clientmail
    ) {
      return alert("Please fill all the fields");
    }
    try {
      const response = await axiosInstance.put(`/updateclient/${params.id}`, {
        name: clientDetails.name,
        email: clientDetails.email,
        phone: clientDetails.phone,
        address: clientDetails.address,
        host: hostDetails.host,
        port: hostDetails.port,
        secure: hostDetails.secure,
        authemail: hostDetails.authemail,
        authpass: hostDetails.authpass,
        clientmail: companyDetails.clientmail,
        ccemail: companyDetails.ccemail,
        bccemail: companyDetails.bccemail,
        whatsapp: companyDetails.whatsapp,
        company: companyDetails.company,
        domain: companyDetails.domain,
        replymail: replyValue,
        mailing: mailValue,
        status: clientDetails.status,
        logo: companyDetails.logo,
      });
      if (response.status === 200) {
        alert("Client Edited Successfully");
        setRefresh(true);
        navigate("/dashboard/clients");
        setClientDetails({
          name: "",
          email: "",
          phone: 0,
          address: "",
          status: "active",
        });
        setCompanyDetails({
          company: "",
          domain: "",
          clientmail: "",
          ccemail: "",
          bccemail: "",
          whatsapp: 0,
          logo: "",
        });
        setHostDetails({
          host: "",
          port: 0,
          secure: true,
          authemail: "",
          authpass: "",
        });
      }
    } catch (error) {
      console.error("Error editing client:", error);
      alert("Failed to edit client. Please try again.");
    }
  };

  useEffect(() => {
    console.log(clients);
    if (params.id) {
      setEditMode(true);
      console.log(params.id);
      const filtrd = clients?.filter(
        (item) => String(item?._id) === String(params.id)
      )[0];
      console.log(filtrd);
      setClientDetails({
        name: filtrd?.name,
        email: filtrd?.email,
        phone: filtrd?.phone,
        address: filtrd?.address,
        status: filtrd?.status,
      });
      setCompanyDetails({
        company: filtrd?.company,
        domain: filtrd?.domain,
        clientmail: filtrd?.clientmail,
        ccemail: filtrd?.ccemail,
        bccemail: filtrd?.bccemail,
        whatsapp: filtrd?.whatsapp,
        logo: filtrd?.logo,
      });
      setHostDetails({
        host: filtrd?.host,
        port: filtrd?.port,
        secure: filtrd?.secure,
        authemail: filtrd?.authemail,
        authpass: filtrd?.authpass,
      });
      setMailValue(filtrd?.mailing);
      setReplyValue(filtrd?.replymail);
      if (filtrd?.replymail === true) {
        setShowHostDetails(true);
      } else {
        setShowHostDetails(false);
      }
    }
  }, [clients]);
  return (
    <Box
      component="form"
      //   onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        height: "90vh",
        overflowY: "auto",
        width: "100%",
      }}
    >
      <Typography
        variant="h5"
        align="center"
        sx={{ mb: 2, color: theme.primary.text }}
      >
        {editMode ? "Edit Client" : "Add Client"}
      </Typography>

      {/* Client Details */}
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "bold", mb: 1, color: theme.primary.text }}
      >
        Client Details
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", sm: "nowrap" },
            gap: "20px",
            flex: 1,
          }}
        >
          <TextFields
            name="name"
            required={true}
            label="Name"
            type="text"
            value={clientDetails.name}
            onChange={(e) =>
              setClientDetails({ ...clientDetails, name: e.target.value })
            }
          />
          <TextFields
            name="email"
            fullWidth
            required={true}
            label="Email"
            type="email"
            value={clientDetails.email}
            onChange={(e) =>
              setClientDetails({ ...clientDetails, email: e.target.value })
            }
          />

          <TextFields
            name="phone"
            fullWidth
            label="Phone"
            type="number"
            required={true}
            value={clientDetails.phone}
            onChange={(e) =>
              setClientDetails({ ...clientDetails, phone: e.target.value })
            }
          />
          <TextFields
            name="address"
            fullWidth
            label="Address"
            type="text"
            value={clientDetails.address}
            onChange={(e) =>
              setClientDetails({
                ...clientDetails,
                address: e.target.value,
              })
            }
          />
        </Box>
      </Box>

      {/* Company Details */}
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "bold", mt: 3, mb: 1, color: theme.primary.text }}
      >
        Company Details
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        <Box
          sx={{
            flex: { xs: "none", sm: 1 },
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box sx={{ width: "100%", margin: "auto" }}>
            <TextFields
              name="clientmail"
              fullWidth
              required={true}
              label="Client Mail"
              type="email"
              value={companyDetails.clientmail}
              onChange={(e) =>
                setCompanyDetails({
                  ...companyDetails,
                  clientmail: e.target.value,
                })
              }
            />
            <FormHelperText sx={{ ml: 1, color: "gray" }}>
              Use a comma (,) to separate multiple email addresses.
            </FormHelperText>
          </Box>
          <Box sx={{ width: "100%", margin: "auto" }}>
            <TextFields
              name="cc"
              fullWidth
              required={true}
              label="CC Emails"
              type="email"
              value={companyDetails.ccemail}
              onChange={(e) =>
                setCompanyDetails({
                  ...companyDetails,
                  ccemail: e.target.value,
                })
              }
            />
            <FormHelperText sx={{ ml: 1, color: "gray" }}>
              Use a comma (,) to separate multiple email addresses.
            </FormHelperText>
          </Box>
          <Box sx={{ width: "100%", margin: "auto" }}>
            <TextFields
              name="bccemail"
              fullWidth
              required={true}
              label="BCC Emails"
              type="email"
              value={companyDetails.bccemail}
              onChange={(e) =>
                setCompanyDetails({
                  ...companyDetails,
                  bccemail: e.target.value,
                })
              }
            />
            <FormHelperText sx={{ ml: 1, color: "gray" }}>
              Use a comma (,) to separate multiple email addresses.
            </FormHelperText>
          </Box>
          <TextFields
            name="whatsappsupport"
            fullWidth
            required={true}
            label="whatsapp Support"
            type="number"
            value={companyDetails.whatsapp}
            onChange={(e) =>
              setCompanyDetails({
                ...companyDetails,
                whatsapp: e.target.value,
              })
            }
          />
          <TextFields
            name="company"
            fullWidth
            required={true}
            label="Company"
            type="text"
            value={companyDetails.company}
            onChange={(e) =>
              setCompanyDetails({
                ...companyDetails,
                company: e.target.value,
              })
            }
          />
          <Box sx={{ width: "100%", margin: "auto" }}>
            <TextFields
              name="domain"
              fullWidth
              required={true}
              label="Domain Name"
              type="url"
              value={companyDetails.domain}
              onChange={(e) =>
                setCompanyDetails({
                  ...companyDetails,
                  domain: e.target.value,
                })
              }
            />
            <FormHelperText sx={{ ml: 1, color: "gray" }}>
              https://domain.com
            </FormHelperText>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "150px",
              height: "150px",
              border: `1px dashed ${theme.primary.text}`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mb: 2,
              backgroundImage: `url(${companyDetails.logo})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {!companyDetails.logo && (
              <Typography sx={{ color: theme.primary.text }}>
                Logo Preview
              </Typography>
            )}
          </Box>
          <Button
            variant="outlined"
            component="label"
            sx={{
              color: theme.primary.buttonText,
              backgroundColor: theme.primary.background,
            }}
          >
            Upload Logo
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={handleFileChange}
            />
          </Button>
        </Box>
      </Box>

      {/* Additional Settings */}
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "bold", mt: 3, mb: 1, color: theme.primary.text }}
      >
        Additional Settings
      </Typography>
      {/* Default Mailing */}
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Typography
            variant="body1"
            sx={{ whiteSpace: "nowrap", color: theme.primary.text }}
          >
            Mailing
          </Typography>
          <Select
            sx={{
              "& .MuiInputBase-input": { color: theme.primary.text },
              "& .MuiInputLabel-root": { color: theme.primary.text },
              border: `1px solid ${theme.primary.text}`,
            }}
            fullWidth
            value={mailValue}
            onChange={(e) => handleMailChange(e.target.value)}
          >
            <MenuItem value="default">Default Mail</MenuItem>
            <MenuItem value="host">Host Mail</MenuItem>
          </Select>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Typography
            variant="body1"
            sx={{ whiteSpace: "nowrap", color: theme.primary.text }}
          >
            Status
          </Typography>
          <Select
            sx={{
              "& .MuiInputBase-input": { color: theme.primary.text },
              "& .MuiInputLabel-root": { color: theme.primary.text },
              border: `1px solid ${theme.primary.text}`,
            }}
            fullWidth
            value={clientDetails.status}
            onChange={(e) =>
              setClientDetails({ ...clientDetails, status: e.target.value })
            }
          >
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="inactive">InActive</MenuItem>
          </Select>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Typography
            variant="body1"
            sx={{ whiteSpace: "nowrap", color: theme.primary.text }}
          >
            Reply Mail
          </Typography>
          <Select
            sx={{
              "& .MuiInputBase-input": { color: theme.primary.text },
              "& .MuiInputLabel-root": { color: theme.primary.text },
              border: `1px solid ${theme.primary.text}`,
            }}
            fullWidth
            value={replyValue}
            onChange={(e) => handleReplyChange(e.target.value)}
          >
            <MenuItem value={true}>Yes</MenuItem>
            <MenuItem value={false}>No</MenuItem>
          </Select>
        </Box>
      </Box>
      {/* Host Details */}
      {showHostDetails && (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            gap: "20px",
            flexWrap: { xs: "wrap", sm: "nowrap" },
          }}
        >
          <TextFields
            name="host"
            fullWidth
            label="Host"
            type="text"
            value={hostDetails.host}
            onChange={(e) =>
              setHostDetails({ ...hostDetails, host: e.target.value })
            }
          />
          <TextFields
            name="port"
            fullWidth
            label="Port"
            type="number"
            value={hostDetails.port}
            onChange={(e) =>
              setHostDetails({ ...hostDetails, port: e.target.value })
            }
          />
          <TextFields
            name="auth.email"
            fullWidth
            label="Auth Email"
            type="email"
            value={hostDetails.authemail}
            onChange={(e) =>
              setHostDetails({
                ...hostDetails,
                authemail: e.target.value,
              })
            }
          />
          <TextFields
            name="auth.pass"
            fullWidth
            label="Auth Password"
            type="password"
            value={hostDetails.authpass}
            onChange={(e) =>
              setHostDetails({
                ...hostDetails,
                authpass: e.target.value,
              })
            }
          />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          margin: "20px 0px",
          gap: "20px",
        }}
      >
        <Buttons
          sx={{
            backgroundColor: theme.primary.background,
            color: theme.primary.buttonText,
          }}
          text={"Cancel"}
          onClick={() => navigate("/dashboard/clients")}
        />

        {editMode ? (
          <Buttons
            sx={{
              backgroundColor: theme.primary.background,
              color: theme.primary.buttonText,
            }}
            text={"Update"}
            type="submit"
            onClick={handleUpdate}
          />
        ) : (
          <Buttons
            sx={{
              backgroundColor: theme.primary.background,
              color: theme.primary.buttonText,
            }}
            text={"Submit"}
            type="submit"
            onClick={handleSubmit}
          />
        )}
      </Box>
    </Box>
  );
};

export default AddClient;
