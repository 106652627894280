import React from "react";
import { Button } from "@mui/material";

const Buttons = ({ text, icon, onClick, sx = {} }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        padding: "5px 15px",
        borderRadius: "10px",
        textTransform: "none",
        ...sx,
      }}
    >
      {icon}
      {text}
    </Button>
  );
};

export default Buttons;
