import React, { useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import ThemeContext from "../contexts/ThemeContext";

const Grids = ({ columns, rows, gridToolbar }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <Box
      sx={{
        minHeight: { sm: "75vh", xs: "30vh" },
        "& .super-app-theme--header": {
          backgroundColor: theme.primary.background,
          color: theme.primary.buttonText,
        },
        "& .MuiDataGrid-cell": {
          color: theme.primary.text,
          textAlign: "center",
          whiteSpace: "nowrap",
        },
        "& .MuiDataGrid-footerContainer": {
          color: theme.primary.text,
          "& .MuiTablePagination-root": {
            color: theme.primary.text, // Change pagination color to red
          },
        },
        "& .MuiTablePagination-actions .MuiButtonBase-root": {
          color: theme.primary.text, // Set the color of the arrows
        },

        // width: "100%",
        // maxWidth: "99%",
        margin: "20px 5px",
        textTransform: "capitalize",
        overflow: "auto",
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        pageSizeOptions={[10, 20, 50, 100]}
        slots={{
          toolbar: gridToolbar ? GridToolbar : null,
        }}
        autoHeight
      />
    </Box>
  );
};

export default Grids;
