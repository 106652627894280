import React, { useContext, useEffect, useState } from "react";
// import {  ModalClose, ModalDialog } from "@mui/joy";
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Modal,
} from "@mui/material";
import AuthContext from "../contexts/authContext";
import Grid from "../components/dataGrid";
import ThemeContext from "../contexts/ThemeContext";

const confirmStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "50%" },
  height: "70%",
  boxShadow: 24,
  p: 1,
  borderRadius: "10px",
  overflow: "auto",
};

const CheckLeads = ({ openLeads, handleCloseLeads, clientId }) => {
  const { clients } = useContext(AuthContext);
  const { theme } = useContext(ThemeContext);
  const [gridToolbar, setGridToolbar] = useState(false);
  const [clientLeads, setClientLeads] = useState([]);
  useEffect(() => {
    const filtr = clients.find((client) => client._id === clientId);
    setClientLeads(filtr?.leads);
  }, [clientId]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 70,
      align: "center",
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 130,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 130,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 130,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "message",
      headerName: "Message",
      minWidth: 130,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "replystatus",
      headerName: "Reply Status",
      minWidth: 130,
      headerAlign: "center",
      headerClassName: "super-app-theme--header",
    },
  ];

  const rows = clientLeads?.map((item, index) => ({
    id: index + 1,
    _id: item?._id,
    name: item?.name,
    email: item?.email,
    phone: item?.phone,
    message: item?.message,
    replystatus: item?.replystatus,
  }));

  return (
    <Modal open={openLeads} onClose={handleCloseLeads}>
      {/* <ModalDialog layout="center" size="lg" variant="soft">
        <ModalClose /> */}
      <Box
        sx={{
          ...confirmStyle,
          border: `2px solid ${theme.primary.text}`,
          backgroundColor: theme.primary.mainBackground,
        }}
      >
        {clientLeads?.length > 0 ? (
          <>
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={gridToolbar}
                    onChange={() => setGridToolbar(!gridToolbar)}
                    name="enableFilters"
                    sx={{
                      color: theme.primary.text, // Checkbox color when unchecked
                      "&.Mui-checked": {
                        color: theme.primary.text, // Checkbox color when checked
                      },
                    }}
                  />
                }
                label="Enable Filters"
                sx={{
                  color: theme.primary.text, // Label color
                }}
              />
            </FormControl>
            <Box>
              <Grid columns={columns} gridToolbar={gridToolbar} rows={rows} />
            </Box>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Typography variant="h4" align="center" sx={{ margin: 3 }}>
              No leads found for this client
            </Typography>
          </Box>
        )}
      </Box>
      {/* </ModalDialog> */}
    </Modal>
  );
};

export default CheckLeads;
